<template>
  <div id="workspace-browse">
    <Portal to="action-bar">
      <ItemActions
        v-if="!isLoading"
        item="workspace"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        :simple-search-applyed.sync="simpleSearchApplyed"
        @refresh="getWorkspaces"
        @search="searchRow"
        @update="getWorkspaces"
      />
    </Portal>

    <div v-if="!isLoading" class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          :simple-search-applyed.sync="simpleSearchApplyed"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <WorkspaceGrid
          v-if="activeView === 'GRID' && breadcrumbs.length === 0"
          :mode="mode"
          :items="items"
          :columns="columns"
          @select="openWorkspace"
          @edit="editWorkspace"
          @more="showWorkspaceDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />

        <WorkspaceList
          v-if="activeView === 'LIST' && breadcrumbs.length === 0"
          :mode="mode"
          :items="items"
          :columns="columns"
          @select="openWorkspace"
          @edit="editWorkspace"
          @more="showWorkspaceDetails"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />

        <!-- breadcrumbs -->
        <div class="col-12">
          <div>
            <Breadcrumbs
              :breadcrumbs="breadcrumbs"
              @click="handleBreadcrumbClick"
              @reset="homeWorkspace"
            />
          </div>
          <q-space />
        </div>
        <!-- ... -->

        <WorkspaceExplore
          v-if="breadcrumbs.length"
          :workspace="selectedWorkspace"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="mdi-briefcase-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      v-if="!isLoading && breadcrumbs.length === 0"
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
    />

    <WorkspaceDetails
      v-if="isWorkspaceDetailsVisible"
      v-model="isWorkspaceDetailsVisible"
      :columns="columns"
      :workspace="selectedWorkspace"
      @edit="editWorkspace"
      @delete="showDeleteConfirmation"
    />

    <ConfirmDeleteWorkspace
      v-model="isDeleteConfirmationVisible"
      :workspace="selectedWorkspace"
      @delete="updateWorkspace"
    />

    <ConfirmRestoreWorkspace
      v-model="isRestoreConfirmationVisible"
      :workspace="selectedWorkspace"
      @restore="updateWorkspace"
    />
  </div>
</template>

<script>
import { workspace } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import WorkspaceGrid from "./components/WorkspaceGrid.vue";
import WorkspaceList from "./components/WorkspaceList.vue";
import WorkspaceDetails from "./components/WorkspaceDetails.vue";
import ConfirmDeleteWorkspace from "./components/ConfirmDeleteWorkspace.vue";
import ConfirmRestoreWorkspace from "./components/ConfirmRestoreWorkspace.vue";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import WorkspaceExplore from "./components/WorkspaceExplore.vue";

export default {
  name: "WorkspacesBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActions,
    ItemActionChips,
    WorkspaceGrid,
    WorkspaceList,
    WorkspaceDetails,
    Pagination,
    ConfirmDeleteWorkspace,
    ConfirmRestoreWorkspace,
    Breadcrumbs,
    WorkspaceExplore,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "repositoriesCount",
          label: "Repositories",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.NUMBER,
        },
        {
          id: this.$nano.id(),
          name: "workflowsCount",
          label: "Workflows",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.NUMBER,
        },
        {
          id: this.$nano.id(),
          name: "portalsCount",
          label: "Portals",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.NUMBER,
        },
        {
          id: this.$nano.id(),
          name: "tasksCount",
          label: "Tasks",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          isFilter: false,
          dataType: DataType.NUMBER,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE,
        },
      ],
      workspaces: [],
      workspaceData: [],
      activeView: "GRID",
      selectedWorkspace: {},
      isWorkspaceDetailsVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "ASC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      breadcrumbs: [],
      simpleSearchApplyed: false,
    };
  },

  computed: {
    items() {
      return this.workspaces.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((workspace) => ({
          id: workspace.id,
          name: workspace.name,
          description: workspace.description,
          color: workspace.color,
          repositoriesCount: this.appendZeroIfNeeded(
            workspace.repositoriesCount
          ),
          workflowsCount: this.appendZeroIfNeeded(workspace.workflowsCount),
          portalsCount: this.appendZeroIfNeeded(workspace.portalsCount),
          tasksCount: this.appendZeroIfNeeded(workspace.tasksCount),
          icon: "mdi-briefcase",
          modifiedBy: workspace.modifiedBy || workspace.createdBy,
          modifiedAt: this.$day.format(
            workspace.modifiedAt || workspace.createdAt
          ),
          inboxCount: {
            text: "New",
            value: 12,
          },
          progressCount: {
            text: "In Progress",
            value: 56,
          },
          completedCount: {
            text: "Completed",
            value: 73,
          },
          actionAccess:
            workspace.createdBy === this.$store.state.session.id ? true : false,
        })),
      }));
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.defaultView();
      },
    },

    "$store.state.defaultView": function () {
      this.defaultView();
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getWorkspaces(),
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    defaultView() {
      let options = this.$store.state.defaultView;
      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      if (Object.keys(options).length) {
        if (options[menu]) {
          let view = options[menu].find((item) => item.subMenu === subMenu);
          if (view) {
            this.activeView = view.view;
          }
        }
      }
    },

    appendZeroIfNeeded(statCount) {
      if (statCount === 0) {
        return 0;
      }
      return statCount < 10 ? `0${statCount}` : statCount;
    },

    findWorkspace(workspaceId) {
      const workspaces = [];
      this.items.forEach((item) => workspaces.push(...item.data));
      return workspaces.find((workspace) => workspace.id === workspaceId);
    },

    showWorkspaceDetails(workspaceId) {
      this.selectedWorkspace = this.findWorkspace(workspaceId);
      this.isWorkspaceDetailsVisible = true;
    },

    editWorkspace(workspaceId) {
      this.$router.push({
        name: "workspace-builder",
        params: { id: String(workspaceId) },
      });
    },

    showDeleteConfirmation(workspaceId) {
      this.selectedWorkspace = this.findWorkspace(workspaceId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(workspaceId) {
      this.selectedWorkspace = this.findWorkspace(workspaceId);
      this.isRestoreConfirmationVisible = true;
    },

    openWorkspace(workspaceId) {
      this.selectedWorkspace = this.findWorkspace(workspaceId);
      // this.breadcrumbs = [];
      // this.breadcrumbs.push({
      //   id: this.selectedWorkspace,
      //   label: this.selectedWorkspace.name,
      //   workspace: this.selectedWorkspace,
      // });
    },

    handleBreadcrumbClick(breadcrumb) {
      console.log(breadcrumb);
      this.breadcrumbs = [];
    },

    homeWorkspace() {
      this.$router.push({
        name: "workspaces-browse",
      });
      this.breadcrumbs = [];
    },

    searchRow(search) {
      if (search) {
        this.workspaces = this.workspaceData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.workspaces = this.workspaceData;
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) {
        this.filterBy = [];
      }
      this.getWorkspaces();
    },

    /* api functions */

    async getWorkspaces() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workspace.getWorkspaces({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;

      this.workspaces = data || [];
      this.workspaceData = data || [];
      this.totalItems = meta?.totalItems || 0;
    },

    async updateWorkspace(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await workspace.updateWorkspace(
        this.selectedWorkspace.id,
        payload
      );

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const mode = payload.isDeleted ? "deleted" : "restored";
      this.$alert.success(
        `Workspace "${this.selectedWorkspace.name}" ${mode} successfully`
      );

      this.getWorkspaces();
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#workspace-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
