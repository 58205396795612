<template>
  <ItemGrid
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #avatar="{ item }">
      <BaseIcon :name="item.icon" :color="item.color" />
    </template>

    <template #content="{ item }">
      <BaseSeparator has-inset />
      <div v-if="false" class="row q-pl-md q-pr-md q-mt-sm q-mb-sm">
        <div class="col">
          <div class="count">{{ item.inboxCount.value }}</div>
          <div class="text">{{ item.inboxCount.text }}</div>
        </div>
        <div class="col text-center">
          <div class="count">{{ item.progressCount.value }}</div>
          <div class="text">{{ item.progressCount.text }}</div>
        </div>
        <div class="col text-right">
          <div class="count">{{ item.completedCount.value }}</div>
          <div class="text">{{ item.completedCount.text }}</div>
        </div>
      </div>
      <div v-if="false" class="workflowReportDetails">
        <div v-for="report in workflowReports" :key="report.id">
          <div class="row">
            <div class="col-1 count">
              {{ report.value }}
            </div>
            <div class="col description">
              {{ report.title }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="row workspaceDetails q-ml-sm q-mr-sm">
        <div
          v-for="stat in stats"
          :key="stat.id"
          class="col-auto q-pa-sm"
          @click="goto(stat.route)"
        >
          <div class="row">
            <div class="col-0-5 count">
              {{ item[stat.label] || "-" }}
            </div>
            <div class="col description">{{ stat.text }}</div>
          </div>
        </div>
      </div>
    </template>
  </ItemGrid>
</template>

<script>
import ItemGrid from "@/components/common/display/ItemGrid.vue";

export default {
  name: "WorkspaceGrid",

  components: { ItemGrid },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      stats: [
        {
          id: this.$nano.id(),
          label: "repositoriesCount",
          text: "File System/s",
          route: "repositories-browse",
        },
        {
          id: this.$nano.id(),
          label: "workflowsCount",
          text: "Workflow/s",
          route: "workflows-browse",
        },
        {
          id: this.$nano.id(),
          label: "portalsCount",
          text: "Portal/s",
          route: "portals-browse",
        },
        {
          id: this.$nano.id(),
          label: "tasksCount",
          text: "Task/s",
          route: "tasks-browse",
        },
      ],
      workflowReports: [
        {
          id: this.$nano.id(),
          title: "Invoice to be paid",
          value: 23,
        },
        {
          id: this.$nano.id(),
          title: "Purchase Orders Relesed",
          value: 18,
        },
        {
          id: this.$nano.id(),
          title: "PO's received to be processed",
          value: 5,
        },
      ],
    };
  },

  methods: {
    handleSelect(workspaceId) {
      this.$emit("select", workspaceId);
    },

    handleEdit(workspaceId) {
      this.$emit("edit", workspaceId);
    },

    handleDelete(workspaceId) {
      this.$emit("delete", workspaceId);
    },

    handleRestore(workspaceId) {
      this.$emit("restore", workspaceId);
    },

    handleMore(workspaceId) {
      this.$emit("more", workspaceId);
    },

    goto(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="scss" scoped>
.count {
  // color: $secondary;
  font-weight: bold;
  margin-right: 5px;
}

.text {
  color: var(--icon-color-inverted);
}

.workflowReportDetails {
  margin-left: 10px;
  margin-right: 10px;
  // background: $secondary-1;
  // background: #f6f7f9;
  background-color: var(--component-bg-color);
  padding: 10px;
  border-radius: 5px;

  .count {
    color: $secondary;
    font-weight: bold;
  }

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }
}

.workspaceDetails {
  .count {
    // color: $primary;
    font-weight: bold;
  }

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }
}
</style>
