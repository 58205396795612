<template>
  <div v-if="workspace" id="workspace-explore">
    <div class="row q-mb-sm">
      <div class="col q-mt-md">
        <div class="workspaceList q-mb-md">
          <div class="row">
            <div class="col-12 q-mb-sm">
              <div
                class="row text-bold q-pa-md"
                style="float: left; font-size: 20px"
              >
                Folders
              </div>
              <div class="row" style="float: right; width: 200px">
                <q-select v-model="modelMonth" outlined :options="monthList" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="row">
                <div class="col-6 q-mb-md">
                  <div
                    class="q-mr-md q-pa-md"
                    style="
                      background: #f1f5f96b;
                      border-radius: 5px;
                      height: 230px;
                      border: 1px solid #f1f5f9;
                    "
                  >
                    <div class="row">
                      <div class="col-8">
                        <div class="row">
                          <div class="col-6 description q-mb-sm">
                            Total Files Uploaded
                          </div>
                          <div class="col-4" style="margin-top: -3%">
                            <BaseActionButton
                              icon="eva-question-mark-circle-outline"
                              size="18px"
                              :is-flat="true"
                              :no-border="true"
                              class="q-mr-xs"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div class="col-2" style="margin-top: -5%">
                            <BaseActionButton
                              icon="eva-plus-circle-outline"
                              size="13px"
                              color="secondary"
                              :is-flat="true"
                              :no-border="true"
                              class="q-mr-xs"
                            />
                          </div>
                          <div
                            class="col-10 description q-mb-sm q-pl-sm"
                            style="font-size: 10px"
                          >
                            Updated Files
                          </div>
                        </div>
                      </div>
                    </div>
                    <BaseSeparator has-inset />
                    <div class="row q-pa-md">
                      <div class="col-12">
                        <div class="title-3 q-pb-md">
                          Total File upload on Repositories
                        </div>
                        <div class="line-4 q-pt-md"><hr /></div>
                      </div>
                    </div>
                    <BaseSeparator has-inset />
                    <div class="row">
                      <div class="col-6 q-pa-md">
                        <div class="col-12 description1 q-mb-sm">Standard</div>
                        <div class="col-12 title-2 q-mb-sm">156 Files</div>
                      </div>
                      <div class="col-6 q-pa-md">
                        <div class="col-12 description2 q-mb-sm">Advanced</div>
                        <div class="col-12 title-2 q-mb-sm">
                          50 out of 150 Credits
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 q-mb-md">
                  <div
                    class="q-mr-md q-pa-md"
                    style="
                      background: #f1f5f96b;
                      border-radius: 5px;
                      height: 230px;
                      border: 1px solid #f1f5f9;
                    "
                  >
                    <div class="row">
                      <div class="col-8">
                        <div class="row q-mb-sm">
                          <div class="col-6 description">Recent Activity</div>
                        </div>
                      </div>
                    </div>
                    <BaseSeparator has-inset />
                    <div class="row q-pa-md">
                      <div class="col-5 q-pr-sm">
                        <div class="title-3">DATE</div>
                      </div>
                      <div class="col-5 q-pr-sm">
                        <div class="title-3">ACTIVITY DETAILS</div>
                      </div>
                      <div class="col-2 q-pr-sm">
                        <div class="title-3">DESCRIPTION</div>
                      </div>
                    </div>
                    <BaseSeparator has-inset />
                    <div class="row q-pa-md">
                      <div class="col-5 q-pr-sm">
                        <div class="title-3">16/10/2023 05:07 PM</div>
                      </div>
                      <div class="col-5 q-pr-sm">
                        <div class="title-3">Invoice8302.pdf</div>
                      </div>
                      <div class="col-2 q-pr-sm">
                        <div class="title-3">
                          Viewd <br />
                          by Web
                        </div>
                      </div>
                    </div>
                    <BaseSeparator has-inset />
                    <div class="row q-pa-md">
                      <div class="col-5 q-pr-md">
                        <div class="title-3">16/10/2023 05:07 PM</div>
                      </div>
                      <div class="col-5 q-pr-md">
                        <div class="title-3">Cradit Note28930.pdf</div>
                      </div>
                      <div class="col-2 q-pr-md">
                        <div class="title-3">
                          Uploads<br />
                          by Web
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="q-mr-md q-pa-md q-mb-md"
                style="
                  background: #f1f5f96b;
                  border-radius: 5px;
                  height: 230px;
                  border: 1px solid #f1f5f9;
                "
              >
                <div class="col description">Files</div>
              </div>
            </div>
          </div>
        </div>
        <div class="workspaceList q-mb-md">
          <div class="row">
            <div class="col-12 q-mb-sm">
              <div
                class="row text-bold q-pa-md"
                style="float: left; font-size: 20px"
              >
                WorkFlows
              </div>
              <div class="row" style="float: right; width: 200px">
                <q-select v-model="modelMonth" outlined :options="monthList" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div
                class="q-mr-md q-pa-sm q-mb-sm"
                style="
                  background: #f1f5f96b;
                  border-radius: 5px;
                  height: 340px;
                  border: 1px solid #f1f5f9;
                "
              >
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-2 description q-mb-sm">
                        <div class="row">
                          <div class="col-12" style="text-align: center">
                            Workflows (2) <br />
                            <BaseActionButton
                              is-flat
                              no-border
                              icon="o_account_tree"
                              color="primary"
                              class="tabButtons"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-2 q-mb-sm"
                        style="
                          text-align: center;
                          border-left: 2px solid #64748b26;
                        "
                      >
                        Received <br />
                        32
                      </div>
                      <div
                        class="col-2 q-mb-sm"
                        style="
                          text-align: center;
                          border-left: 2px solid #64748b26;
                        "
                      >
                        Pending<br />
                        12
                      </div>
                      <div
                        class="col-2 q-mb-sm"
                        style="
                          text-align: center;
                          border-left: 2px solid #64748b26;
                        "
                      >
                        Processed <br />
                        4
                      </div>
                      <div
                        class="col-2 q-mb-sm"
                        style="
                          text-align: center;
                          border-left: 2px solid #64748b26;
                        "
                      >
                        MetSLA <br />
                        80%
                      </div>
                      <div
                        class="col-2 q-mb-sm"
                        style="
                          text-align: center;
                          border-left: 2px solid #64748b26;
                        "
                      >
                        ExceedSLA <br />
                        40%
                      </div>
                    </div>
                  </div>
                </div>
                <BaseSeparator has-inset />
                <div class="row q-pa-sm">
                  <div class="col-11">
                    <div class="row">
                      <div class="col-1">
                        <BaseActionButton
                          icon="eva-email-outline"
                          size="18px"
                          :is-flat="true"
                          :no-border="true"
                          class="q-mr-xs"
                        />
                      </div>
                      <div class="col-11" style="font-size: 12px">
                        Reg-02930 Invoice Approvel <br />
                        <b>Customer Name:</b> ABC Company;<b>Invoice Amount:</b>
                        19405.00 AED;<b>Invoice Date:</b> 26-Oct-2023
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-1 has-link"
                    @click="$router.push({ name: 'workspaces-browse' })"
                  >
                    View
                  </div>
                </div>
                <BaseSeparator has-inset />
                <div class="row q-pa-sm">
                  <div class="col-11">
                    <div class="row">
                      <div class="col-1">
                        <BaseActionButton
                          icon="eva-email-outline"
                          size="18px"
                          :is-flat="true"
                          :no-border="true"
                          class="q-mr-xs"
                        />
                      </div>
                      <div class="col-11" style="font-size: 12px">
                        Reg-02930 Invoice Approvel <br />
                        <b>Customer Name:</b> ABC Company;<b>Invoice Amount:</b>
                        19405.00 AED;<b>Invoice Date:</b> 26-Oct-2023
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-1 has-link"
                    @click="$router.push({ name: 'workspaces-browse' })"
                  >
                    View
                  </div>
                </div>
                <BaseSeparator has-inset />
                <div class="row q-pa-sm">
                  <div class="col-11">
                    <div class="row">
                      <div class="col-1">
                        <BaseActionButton
                          icon="eva-email-outline"
                          size="18px"
                          :is-flat="true"
                          :no-border="true"
                          class="q-mr-xs"
                        />
                      </div>
                      <div class="col-11" style="font-size: 12px">
                        Reg-02930 Invoice Approvel <br />
                        <b>Customer Name:</b> ABC Company;<b>Invoice Amount:</b>
                        19405.00 AED;<b>Invoice Date:</b> 26-Oct-2023
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-1 has-link"
                    @click="$router.push({ name: 'workspaces-browse' })"
                  >
                    View
                  </div>
                </div>
                <BaseSeparator has-inset />
                <div class="row q-pa-sm">
                  <div class="col-11">
                    <div class="row">
                      <div class="col-1">
                        <BaseActionButton
                          icon="eva-email-outline"
                          size="18px"
                          :is-flat="true"
                          :no-border="true"
                          class="q-mr-xs"
                        />
                      </div>
                      <div class="col-11" style="font-size: 12px">
                        Reg-02930 Invoice Approvel <br />
                        <b>Customer Name:</b> ABC Company;<b>Invoice Amount:</b>
                        19405.00 AED;<b>Invoice Date:</b> 26-Oct-2023
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-1 has-link"
                    @click="$router.push({ name: 'workspaces-browse' })"
                  >
                    View
                  </div>
                </div>
                <BaseSeparator has-inset />
                <div class="row q-pa-sm">
                  <div
                    class="col-12 has-link"
                    style="text-align: left"
                    @click="$router.push({ name: 'workspaces-browse' })"
                  >
                    11 More Request
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-6 q-mb-md">
                  <div
                    class="q-mr-md q-pa-sm"
                    style="border: 1px solid #f1f5f9; height: 340px"
                  >
                    <table style="border: 2px solid teal">
                      <tr>
                        <th>Cycle Time Summary</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td>Colsed</td>
                        <td>2043</td>
                      </tr>
                      <tr>
                        <td>Avg Duration(days)</td>
                        <td>1.955</td>
                      </tr>
                      <tr>
                        <td>Exp Duration(days)</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td>Std Diviation</td>
                        <td>1.7289</td>
                      </tr>
                      <tr>
                        <td>On Time Cases</td>
                        <td>1698</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkspaceExplore",

  props: {
    workspace: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      inboxCount: {
        text: "New",
        value: 12,
      },
      progressCount: {
        text: "In Progress",
        value: 56,
      },
      completedCount: {
        text: "Completed",
        value: 73,
      },
      monthList: [
        {
          id: this.$nano.id(),
          label: "This Week",
          value: "This Week",
        },

        {
          id: this.$nano.id(),
          label: "This Month",
          value: "This Month",
        },

        {
          id: this.$nano.id(),
          label: "This Quarter",
          value: "This Quarter",
        },

        {
          id: this.$nano.id(),
          label: "This Year",
          value: "This Year",
        },

        {
          id: this.$nano.id(),
          label: "Yesterday",
          value: "Yesterday",
        },

        {
          id: this.$nano.id(),
          label: "Previous Week",
          value: "Previous Week",
        },

        {
          id: this.$nano.id(),
          label: "Previous Month",
          value: "Previous Month",
        },

        {
          id: this.$nano.id(),
          label: "Previous Quarter",
          value: "Previous Quarter",
        },

        {
          id: this.$nano.id(),
          label: "Previous Year",
          value: "Previous Year",
        },

        {
          id: this.$nano.id(),
          label: "Custom",
          value: "Custom",
        },
      ],
      workflow: "",
      modelMonth: "Today",
    };
  },

  computed: {},

  watch: {},

  methods: {
    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#workspace-explore {
  .description {
    color: #475569;
    margin-bottom: 4px;
    font-size: 15px;
  }
  .description1 {
    color: #1f78f5;
    margin-bottom: 4px;
    font-size: 15px;
  }
  .description2 {
    color: #4ddeff;
    margin-bottom: 4px;
    font-size: 15px;
  }
  .count {
    color: $secondary;
    font-weight: bold;
  }
  .has-link {
    cursor: pointer;
    color: var(--secondary);
    float: right;
    font-weight: 600;
    text-align: center;
    font-size: 12px;
  }

  .has-link:hover {
    color: $primary;
    font-weight: 600;
  }
  .workspaceList {
    // height: calc(calc(100vh - 250px) / 2);
    // height: 100%;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 10px;
    background: white;
  }

  .workspaceBox {
    border: 1px solid #f1f5f9;
    padding: 10px;
    border-radius: 10px;
    background: #f1f5f96b;
  }
  .line-4 hr {
    height: 10px;
    border: 0;
    background: #ffae24;
  }
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

td,
th {
  text-align: left;
  padding: 8px;
}
th {
  background-color: teal;
  color: white;
}

tr:nth-child(odd) {
  background-color: #f1f5f9;
}
.q-btn {
  text-transform: none !important;
}

.q-field {
  width: 200px;
}
.tabButtons {
  margin-left: 16px;
}
</style>
