import { render, staticRenderFns } from "./ConfirmRestoreWorkspace.vue?vue&type=template&id=3924968c&scoped=true&"
import script from "./ConfirmRestoreWorkspace.vue?vue&type=script&lang=js&"
export * from "./ConfirmRestoreWorkspace.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3924968c",
  null
  
)

export default component.exports