<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
  >
    <template #avatar="{ item }">
      <div class="row items-center justify-center" style="width: 36px">
        <BaseIcon :name="item.icon" :color="item.color" />
      </div>
    </template>

    <template v-for="stat in stats" #[stat]="{ value }">
      <div :key="stat" class="stat" @click.stop="goto(stat)">
        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "WorkspaceList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      stats: [
        "repositoriesCount",
        "workflowsCount",
        "portalsCount",
        "tasksCount",
      ],
    };
  },

  methods: {
    appendZeroIfNeeded(statCount) {
      if (statCount === 0) {
        return 0;
      }
      return statCount < 10 ? `0${statCount}` : statCount;
    },

    handleSelect(workspaceId) {
      this.$emit("select", workspaceId);
    },

    handleEdit(workspaceId) {
      this.$emit("edit", workspaceId);
    },

    handleDelete(workspaceId) {
      this.$emit("delete", workspaceId);
    },

    handleRestore(workspaceId) {
      this.$emit("restore", workspaceId);
    },

    handleMore(workspaceId) {
      this.$emit("more", workspaceId);
    },

    goto(stat) {
      if (stat === "repositoriesCount") {
        this.$router.push({ name: "repositories-browse" });
      } else if (stat === "workflowsCount") {
        this.$router.push({ name: "workflows-browse" });
      } else if (stat === "portalsCount") {
        this.$router.push({ name: "portals-browse" });
      } else if (stat === "tasksCount") {
        this.$router.push({ name: "tasks-browse" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
